import React from "react";
import PrimaryLayout from "../../../Component/layouts/PrimaryLayout";
import { useContext } from "react";
import { LoanReqContext } from "../../../Providers/LoanReqProvider";
import CustomTable from "../../../Component/Shared/CustomTable";

const tableHead = [
  { id: 1, name: "Order ID", width: 85 },
  { id: 2, name: "Customer ID", width: 120 },
  { id: 3, name: "Customers", width: 100 },
  { id: 4, name: "Customers Name", width: 160 },
  { id: 5, name: "Product Name", width: 135 },
  { id: 6, name: "Loan Amount", width: 125 },
  { id: 7, name: "Starting Date", width: 125 },
  { id: 8, name: "Closing Date", width: 120 },
  { id: 9, name: "Due Amount", width: 120 },
  { id: 10, name: "Loan Status", width: 115 },
  { id: 11, name: "Action", width: 90 },
];

const Repayment = () => {
  const { repayment, getRepaymentList } = useContext(LoanReqContext);
  // console.log(repayment);

  const repaymentItems2 = repayment?.map((data) => {
    return {
      id: data?.id,
      utr: data?.utr,
      voucher_img: data?.voucher_img,
      amount: data?.amount,
      date: data?.created_at,
    };
  });

  // console.log(repaymentItems2[0]);

  const repaymentItems = repayment?.map(
    ({
      // id,

      user_details: { user_no, user_image, full_name },
      product_details: { name, loan_amount, start_date },
      loan_details: {
        id,
        order_no,
        closing_date,
        due_amount,
        loan_status,
        payment_status,
      },
    }) => ({
      id,
      order_no,
      user_no,
      user_image,
      full_name,
      name,
      loan_amount,
      start_date,
      closing_date,
      due_amount,
      loan_status,
    })
  );
  // console.log(repaymentItems);
  return (
    <PrimaryLayout pageTitle="Loans" calender={true}>
      <div className="bg-[#F0F2F5] px-[15px] md:px-[30px]">
        <CustomTable
          pageTitle="All Repayment"
          tableHead={tableHead}
          tableData={repaymentItems}
          changeFont="font-poppins"
          searchOption={true}
          searchMethod="name"
          statusOption={true}
          getLoanList={getRepaymentList}
          actionBtn={["View Profile", "Edit Details", "Transaction Details"]}
          transData={repaymentItems2[0]}
        />
      </div>
    </PrimaryLayout>
  );
};

export default Repayment;
